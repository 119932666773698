import React from 'react'
import customMoment from '../../customComponents/customMoment'
import { convertMonthFromDate } from '../../functions'

interface Props {
    //add props value
}

interface State {
    time: string
    date: string
}

export default class Clock extends React.Component<Props, State> {
    public intervalID

    constructor(props) {
        super(props)
        this.state = {
            time: customMoment().format('HH:mm:ss'),
            date: convertMonthFromDate(customMoment().format('DD M YYYY'), ' ')
        }
    }

    componentDidMount() {
        this.intervalID = setInterval(() => this.tick(), 1000)
    }

    componentWillUnmount() {
        clearInterval(this.intervalID)
    }

    tick() {
        this.setState({
            time: customMoment().format('HH:mm:ss'),
            date: convertMonthFromDate(customMoment().format('DD M YYYY'), ' ')
        })
    }

    render() {
        return (
            <div className="time-section">
                <span className="date-value">{this.state.date}</span>
                <div className="time-container">
                    <span className="time-value">{this.state.time}</span>
                </div>
            </div>
        )
    }
}
