import React from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { syncAppStartData } from '../../actions/multiple_actions'
import '../../styles/HomePage.sass'
import { connect } from 'react-redux'
import { Intervention } from '../../types/api'
import { Reducers } from '../../types/reducers'
import Loader from '../elements/Loader'
import RiverLevel from '../elements/RiverLevel'
import AlertTable from '../elements/AlertTable'
import MeteoCard from '../elements/MeteoCard'
import ReactMapGL, { Marker, Layer } from 'react-map-gl'
import { MAPBOX_TOKEN } from '../../constants/env_variables'
import { getActualWorkShift } from '../../functions'

const dispatchProps = {
    syncAppStartData
}

function mapStateToProps({ data, settings }: Reducers) {
    return {
        data,
        settings
    }
}

interface Props extends DispatchProps, StateProps {
    //Props
}

interface State {
    openFiltersList: boolean
    selectedIntervention: Intervention
    viewport: { longitude: number; latitude: number; zoom: number }
    interventionsVisible: boolean
    riverLevelsVisible: boolean
    meteoVisible: boolean
}

class HomePage extends React.Component<Props, State> {
    state = {
        openFiltersList: false,
        selectedIntervention: null,
        viewport: { longitude: 11.6589668, latitude: 44.6060402, zoom: 12 },
        interventionsVisible: true,
        riverLevelsVisible: true,
        meteoVisible: true
    }



    triggerFilters = (): void => {
        this.setState({
            openFiltersList: !this.state.openFiltersList
        })
    }

    render() {
        const rivers = this.props.data.riverLevels
        const alerts = this.props.data.alerts
        const forecast = this.props.data.forecast
        const earthquakes = this.props.data.earthquakes
        const interventions = this.props.data.interventions
        const activeInterventions = this.props.data.activeInterventions
        const radarMeteoTimestamps = this.props.data.radarMeteoTimestamps
        if (
            rivers === null ||
            alerts === null ||
            forecast === null ||
            earthquakes === null ||
            interventions === null ||
            activeInterventions === null ||
            radarMeteoTimestamps === null
        )
            return <Loader/>

        const alert = alerts.today.filter(alert => alert.area === 'D' || alert.area === 'D1')

        const lastRadarImage = radarMeteoTimestamps[radarMeteoTimestamps.length - 1]

        const radar = {
            'type': 'raster',
            'tiles': [
                `https://tilecache.rainviewer.com/v2/radar/${lastRadarImage}/512/{z}/{x}/{y}/1/1_1.png`
            ],
            'tileSize': 512,
            'attribution':
                'Radar meteo by <a target="_top" rel="noopener" href="https://www.rainviewer.com/">Rain viewer</a>'
        }

        const parkLayer = {
            id: 'simple-tiles',
            type: 'raster',
            source: radar,
            minzoom: 0,
            maxzoom: 22
        }

        return (
            <div className={`App ${this.props.settings.drawerCollapsed ? 'resized-content' : ''}`}>
                <div className="meteo-widget">
                    <MeteoCard forecast={forecast}/>
                </div>
                <div className="main-card card">
                    <div className="alerts-section">
                        <div className="workshift">
                            <div className="workshift__header">
                                <span>TURNO</span>
                                <div className={`${getActualWorkShift().night ? 'night-icon' : 'day-icon'}`} />
                            </div>
                            <div className="workshift__body">{getActualWorkShift().shift}</div>
                        </div>
                        <AlertTable home={true} data={alert} />
                    </div>
                    <div className="map-wrapper">
                        <div className="map-container">
                            <ReactMapGL
                                mapboxApiAccessToken={MAPBOX_TOKEN}
                                width="100%"
                                height="100%"
                                {...this.state.viewport}
                                mapStyle="mapbox://styles/mapbox/dark-v9"
                                onViewportChange={viewport => this.setState({ viewport })}
                            >
                                {
                                    this.state.meteoVisible ? <Layer {...parkLayer} /> : null
                                }
                                <Marker latitude={44.6060402} longitude={11.6589668} offsetLeft={-40} offsetTop={-50}>
                                    <div className="firestation-sprite tooltip-trigger center-vertical-tooltip-trigger">
                                        <div className="tooltip normal-tooltip dark-tooltip top-tooltip">Il tuo quartier generale si trova qui</div>
                                    </div>
                                </Marker>
                                {
                                    this.state.interventionsVisible
                                        ? activeInterventions.map(activeIntervention => {
                                            return (
                                                <Marker
                                                    latitude={activeIntervention.position.latitude}
                                                    longitude={activeIntervention.position.longitude}
                                                    offsetLeft={-40}
                                                    offsetTop={-50}
                                                >
                                                    <img
                                                        src="https://s3.eu-central-1.amazonaws.com/citycommerce/media/public/pin.svg"
                                                        height={50}
                                                        width={50}
                                                        alt="Intervention"
                                                    />
                                                </Marker>
                                            )
                                        })
                                        : null
                                }
                            </ReactMapGL>
                        </div>
                        <div className="map-info-panel">
                            <div className="map-filters-controller">
                                <div className="filters-wrapper">
                                    <div className="filter-trigger" onClick={() => this.triggerFilters()}>
                                        <span>Filtri</span>
                                        <div className={`triangle-icon ${this.state.openFiltersList ? '' : 'triangle-down'}`} />
                                    </div>
                                    <div className={`filters-list ${this.state.openFiltersList ? 'visible-list' : ''}`}>
                                        <div className="filter">
                                            <span>Fiumi</span>
                                            <div className="md-checkbox">
                                                <input
                                                    id="checkO"
                                                    type="checkbox"
                                                    onChange={() =>
                                                        this.setState({
                                                            riverLevelsVisible: !this.state.riverLevelsVisible
                                                        })
                                                    }
                                                    checked={this.state.riverLevelsVisible}
                                                />
                                                <label htmlFor="checkO"></label>
                                            </div>
                                        </div>
                                        <div className="filter">
                                            <span>Interventi</span>
                                            <div className="md-checkbox">
                                                <input
                                                    id="checkT"
                                                    type="checkbox"
                                                    onChange={() =>
                                                        this.setState({
                                                            interventionsVisible: !this.state.interventionsVisible
                                                        })
                                                    }
                                                    checked={this.state.interventionsVisible}
                                                />
                                                <label htmlFor="checkT"></label>
                                            </div>
                                        </div>
                                        <div className="filter">
                                            <span>Meteo</span>
                                            <div className="md-checkbox">
                                                <input
                                                    id="checkTh"
                                                    type="checkbox"
                                                    onChange={() =>
                                                        this.setState({
                                                            meteoVisible: !this.state.meteoVisible
                                                        })
                                                    }
                                                    checked={this.state.meteoVisible}
                                                />
                                                <label htmlFor="checkTh"></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="rivers-alerts">
                                <RiverLevel river={rivers.reno}/>
                                <RiverLevel river={rivers.idiceMartino}/>
                                <RiverLevel river={rivers.idiceAntonio}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

type DispatchProps = typeof dispatchProps
type StateProps = ReturnType<typeof mapStateToProps>

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(dispatchProps, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage)
