import React from 'react'
import { connect } from 'react-redux'
import { BrowserRouter, Route } from 'react-router-dom'
import { bindActionCreators, Dispatch } from 'redux'
import { syncAppStartData } from './actions/multiple_actions'
import Drawer from './components/elements/Drawer'
import Login from './components/elements/Login'
import AlertsPage from './components/pages/AlertsPage'
import HomePage from './components/pages/HomePage'
import InterventionsPage from './components/pages/InterventionsPage'
import RiverLevelsPage from './components/pages/RiverLevelsPage'
import WeatherPage from './components/pages/WeatherPage'
import SettingsPage from './components/pages/SettingsPage'
import { Reducers } from './types/reducers'
import TeamPage from './components/pages/TeamPage'
import './styles/global.sass'

const dispatchProps = {
    syncAppStartData
}

function mapStateToProps({ user }: Reducers) {
    return {
        user
    }
}

interface Props extends DispatchProps, StateProps {
    navigation?: any
}

class App extends React.Component<Props> {
    public intervalUpdate

    componentDidMount(): void {
        if (this.props.user.token !== null) {
            this.props.syncAppStartData()
            this.intervalUpdate = setInterval(() => this.props.syncAppStartData(), 1000 * 60 * 5)
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if (prevProps.user.token === null && this.props.user.token !== null) {
            this.props.syncAppStartData()
            this.intervalUpdate = setInterval(() => this.props.syncAppStartData(), 1000 * 60 * 5)
        } else if (prevProps.user.token !== null && this.props.user.token === null) {
            clearInterval(this.intervalUpdate)
        }
    }

    componentWillUnmount() {
        clearInterval(this.intervalUpdate)
    }

    render() {
        return (
            <BrowserRouter>
                {this.props.user.token !== null ? (
                    <div>
                        <Drawer />
                        <Route exact path="/" component={HomePage} />
                        <Route path="/weather" component={WeatherPage} />
                        <Route path="/rivers" component={RiverLevelsPage} />
                        <Route path="/alerts" component={AlertsPage} />
                        <Route path="/interventions" component={InterventionsPage} />
                        <Route path="/team" component={TeamPage} />
                        <Route path="/settings" component={SettingsPage} />
                    </div>
                ) : (
                    <Login />
                )}
            </BrowserRouter>
        )
    }
}

type DispatchProps = typeof dispatchProps
type StateProps = ReturnType<typeof mapStateToProps>

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(dispatchProps, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
