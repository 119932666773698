import axios from '../customComponents/customAxios'
import { LoginData, User } from '../types/interfaces'
import { ReducerUser } from '../types/reducers'

// Azione per fare il login
interface LoginAction {
    type: 'data/login'
    payload: () => Promise<ReducerUser>
}

// Azione andata a buon fine per fare il login
export interface LoginActionFulfilled {
    type: 'data/login_FULFILLED'
    payload: ReducerUser
}

// Azione non andata a buon fine per fare il login
export interface LoginActionRejected {
    type: 'data/login_REJECTED'
    payload: ReducerUser
}

// Fa il login
export function login(userData: LoginData): LoginAction {
    return {
        type: 'data/login',
        payload: async () => {
            const token = await axios.post('access/login/', userData).then(result => result.data.key)
            axios.defaults.headers.common.Authorization = 'Token ' + token
            const user: User = await axios.get('dashboard/user/').then(response => response.data[0])
            return { token, ...user }
        }
    }
}

// Azione per fare il logout
export interface LogoutAction {
    type: 'data/logout'
}

// Fa il logout
export function logout(): LogoutAction {
    return {
        type: 'data/logout'
    }
}
