import React from 'react'
import { Reducers } from '../../types/reducers'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { syncAppStartData } from '../../actions/multiple_actions'
import '../../styles/elements/AlertTable.sass'

interface State {
    // State
}

const dispatchProps = {
    syncAppStartData
}

function mapStateToProps({ data, settings }: Reducers) {
    return {
        data,
        settings
    }
}

interface Props extends DispatchProps, StateProps {}

class AlertsPage extends React.Component<Props, State> {
    places: string[] = ['A1', 'A2', 'B1', 'B2', 'C1', 'C2', 'D1', 'D2', 'E1', 'E2', 'F', 'G1', 'G2', 'H1', 'H2']
    alerts: string[] = [
        'Criticità idraulica',
        'Criticità idrogeologica',
        'Criticità per temporali',
        'Vento',
        'Temperature estreme',
        'Neve',
        'Pioggia che gela',
        'Stato del mare',
        'Criticità costiera'
    ]
    render() {
        return (
            <div className={`App ${this.props.settings.drawerCollapsed ? 'resized-content' : ''}`}>
                <table>
                    <thead>
                        <tr>
                            <th />
                            {this.places.map(element => {
                                return <th>{element}</th>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {this.alerts.map(element => {
                            return (
                                <tr>
                                    <td>{element}</td>
                                    {this.places.forEach((el, index) => {
                                        return (
                                            <td>
                                                <div key={index} className="point alert-green" />
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        )
    }
}

type DispatchProps = typeof dispatchProps
type StateProps = ReturnType<typeof mapStateToProps>

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(dispatchProps, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertsPage)
