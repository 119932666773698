import React from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { bindActionCreators, Dispatch } from 'redux'
import { closeDrawer, openDrawer } from '../../actions/settings'
import { logout } from '../../actions/user'
import '../../styles/elements/Drawer.sass'
import { Reducers } from '../../types/reducers'
import Clock from '../elements/Clock'

const dispatchProps = {
    logout,
    openDrawer,
    closeDrawer
}

function mapStateToProps({ user, settings }: Reducers) {
    return {
        user,
        settings
    }
}

interface Props extends DispatchProps, StateProps {
    // Props
}

class Drawer extends React.Component<Props> {
    drawerInteract = (): void => {
        if (this.props.settings.drawerCollapsed) this.props.openDrawer()
        else this.props.closeDrawer()
    }

    render() {
        const { drawerCollapsed } = this.props.settings
        return (
            <div>
                <div className={`horizontal-bar ${drawerCollapsed ? 'horizontal-bar-resized' : ''}`}>
                    <Clock />
                    <div className="right-buttons-section">
                        <div className="user-name">{`${this.props.user.firstName} ${this.props.user.lastName}`}</div>
                        <div className="user-image">
                            <div className="user-sprite"></div>
                        </div>
                        <span className="logout-btn" onClick={() => this.props.logout()}>
                            <div className="logout-ico-btn tooltip-trigger">
                                <div className="logout-icon" />
                                <span className="tooltip small-tooltip bottom-tooltip accent-tooltip">Esci</span>
                            </div>
                        </span>
                    </div>
                </div>
                <div className={`navbar ${drawerCollapsed ? 'closed-navbar' : ''}`}>
                    <div className="app-logo">
                        <div className="name-logo"></div>
                        <div className="tablet-logo" />
                    </div>
                    <ul>
                        <li>
                            <NavLink to="/" exact activeClassName="active">
                                <div className="home-icon nav-ico" />
                                <span>Overview</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/weather" activeClassName="active">
                                <div className="cloud-icon nav-ico" />
                                <span>Meteo</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/rivers" activeClassName="active">
                                <div className="river-icon nav-ico" />
                                <span>Idrometri</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/alerts" activeClassName="active">
                                <div className="alert-icon nav-ico" />
                                <span>Allerte</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/interventions" activeClassName="active">
                                <div className="interventions-icon nav-ico" />
                                <span>Interventi</span>
                            </NavLink>
                        </li>
                    </ul>
                    <div className="settings-section">
                        <ul>
                            <li>
                                <NavLink to="/team" activeClassName="active">
                                    <div className="team-icon nav-ico" />
                                    <span>Squadra</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/settings" activeClassName="active">
                                    <div className="settings-icon nav-ico"></div>
                                    <span>Impostazioni</span>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                    <div className="bottom-section">
                        <div className="credits">
                            Made by <a href="https://massbit.dev/">Massbit</a>
                        </div>
                        <div className="bottom-button" onClick={() => this.drawerInteract()}>
                            <div className="arrow-icon" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

type DispatchProps = typeof dispatchProps
type StateProps = ReturnType<typeof mapStateToProps>

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(dispatchProps, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Drawer)
