import axios from '../customComponents/customAxios'

import produce from 'immer'
import { ReducerUser } from '../types/reducers'
import { ReducerUserActions } from '../types/reducersActions'

const defaultValue: ReducerUser = {
    token: null,
    id: null,
    uuid: null,
    firstName: '',
    lastName: '',
    mail: '',
    acceptInterventionNotifiers: false
}

export default function dataState(state = defaultValue, action: ReducerUserActions) {
    return produce(state, (draft) => {
        switch (action.type) {
            case 'data/login_FULFILLED':
                console.log(action.payload)
                return  action.payload
            case 'data/logout':
                axios.defaults.headers.common.Authorization = ''
                draft.token = null
                break
            default:
                break
        }
    })
}

