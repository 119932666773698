import produce from 'immer'
import { ReducerGraphic } from '../types/reducers'
import { ReducerGraphicActions } from '../types/reducersActions'

const defaultValue: ReducerGraphic = {
    loginError: false
}

export default function dataState(state = defaultValue, action: ReducerGraphicActions) {
    return produce(state, (draft) => {
        switch (action.type) {
            case 'data/login_REJECTED':
                draft.loginError = true
                break
            default:
                break
        }
    })
}
