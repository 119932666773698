import React from 'react'
import '../../styles/elements/AlertTable.sass'
import { Alert, AlertEvent } from '../../types/api'

interface Props {
    data: Alert[]
    home: boolean
}

interface State {
    // State
}

export default class AlertTable extends React.Component<Props, State> {
    types() {
        const types = [
            'Criticità idraulica',
            'Criticità idrogeologica',
            'Criticità per temporali',
            'Vento',
            'Temperature estreme',
            'Neve',
            'Pioggia che gela',
            'Stato del mare',
            'Criticità costiera'
        ]
        if (this.props.home) types.splice(7, 8)

        return types
    }

    joinEvents() {
        const events: AlertEvent[] = []

        this.props.data.map(alert => {
            return alert.events.map(event => {
                return events.push(event)
            })
        })

        if (this.props.home) events.splice(7, 8)

        return events
    }

    render() {
        const events = this.joinEvents()
        const icons = ['idraulic', 'idrogeo', 'bad-weather', 'wind', 'extreme-temp', 'snow', 'ice-rain']

        return (
            <div className="chips-container">
                {this.types().map((type, index) => (
                    <div key={`alert-home-${index}`} className={`chip alert-${events[index].color} tooltip-trigger`}>
                        <div className="alert-icon-wrapper">
                            <div className={`${icons[index]}-alert`} key={type}></div>
                        </div>
                        <span className="tooltip normal-tooltip dark-tooltip right-tooltip">{type}</span>
                    </div>
                ))}
            </div>
        )
    }
}
