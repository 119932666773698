import React from 'react'
import Lottie from 'react-lottie'
import '../../styles/elements/Loader.sass'

export default class Loader extends React.Component {
    render() {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: require('../../media/animations/circle-loader.json'),
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        }

        return (
            <div className="loader-wrapper">
                <Lottie options={defaultOptions} height={120} width={120} />
            </div>
        )
    }
}
