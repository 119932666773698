import customAxios from '../customComponents/customAxios'
import axios from 'axios'
import { Alerts, Earthquakes, ForecastData, RiverLevels, Intervention } from '../types/api'
import moment from 'moment'

moment().locale('it')

/**
 * METEO
 */

// Azione per scaricare i dati del meteo
interface SyncForecastAction {
    type: 'data/syncForecast'
    payload: Promise<ForecastData>
}

// Azione andata a buon fine per scaricare i dati del meteo
export interface SyncForecastActionFulfilled {
    type: 'data/syncForecast_FULFILLED'
    payload: ForecastData
}

// Azione non andata a buon fine per scaricare i dati del meteo
export interface SyncForecastActionRejected {
    type: 'data/syncForecast_REJECTED'
    payload: ForecastData
}

// Scarica i dati del meteo
export function syncForecast(): SyncForecastAction {
    return {
        type: 'data/syncForecast',
        payload: customAxios.get('dashboard/forecast/').then(response => {
            return response.data
        })
    }
}

/**
 * LIVELLI IDROMETRICI
 */

// Azione per scaricare i dati dei livelli dei fiumi
interface SyncRiverLevelAction {
    type: 'data/syncRiverLevel'
    payload: Promise<RiverLevels>
}

// Azione andata a buon fine per scaricare i dati dei livelli dei fiumi
export interface SyncRiverLevelActionFulfilled {
    type: 'data/syncRiverLevel_FULFILLED'
    payload: RiverLevels
}

// Azione non andata a buon fine per scaricare i dati dei livelli dei fiumi
export interface SyncRiverLevelActionRejected {
    type: 'data/syncRiverLevel_REJECTED'
    payload: RiverLevels
}

// Scarica i dati dei livelli dei fiumi
export function syncRiverLevel(): SyncRiverLevelAction {
    return {
        type: 'data/syncRiverLevel',
        payload: customAxios.get('dashboard/river_level/').then(response => response.data)
    }
}

/**
 * ALLERTE
 */

// Azione per scaricare i dati delle allerte
interface SyncAlertAction {
    type: 'data/syncAlert'
    payload: Promise<Alerts>
}

// Azione andata a buon fine per scaricare i dati delle allerte
export interface SyncAlertActionFulfilled {
    type: 'data/syncAlert_FULFILLED'
    payload: Alerts
}

// Azione non andata a buon fine per scaricare i dati deelle allerte
export interface SyncAlertActionRejected {
    type: 'data/syncAlert_REJECTED'
    payload: Alerts
}

// Scarica i dati delle allerte
export function syncAlert(): SyncAlertAction {
    return {
        type: 'data/syncAlert',
        payload: customAxios.get('dashboard/alert/').then(response => response.data)
    }
}

/**
 * TERREMOTI
 */

// Azione per scaricare i dati dei terremoti
interface SyncEarthquakeAction {
    type: 'data/syncEarthquake'
    payload: Promise<Earthquakes>
}

// Azione andata a buon fine per scaricare i dati dei terremoti
export interface SyncEarthquakeActionFulfilled {
    type: 'data/syncEarthquake_FULFILLED'
    payload: Earthquakes
}

// Azione non andata a buon fine per scaricare i dati dei terremoti
export interface SyncEarthquakeActionRejected {
    type: 'data/syncEarthquake_REJECTED'
    payload: Earthquakes
}

// Scarica i dati dei terremoti
export function syncEarthquake(): SyncEarthquakeAction {
    const startDate = moment()
        .subtract(7, 'd')
        .format('YYYY-MM-DD')
    const endDate = moment().format('YYYY-MM-DD')

    return {
        type: 'data/syncEarthquake',
        payload: customAxios
            .get(`dashboard/earthquake/?start=${startDate}&end=${endDate}`, {
                headers: { 'Content-Type': 'text/xml' }
            })
            .then(response => {
                return {
                    emilia: JSON.parse(response.data.emilia)['q:quakeml'].eventParameters.event,
                    italy: JSON.parse(response.data.italy)['q:quakeml'].eventParameters.event
                }
            })
    }
}

/**
 * INTERVENTI
 */

// Azione per scaricare i dati degli interventi
interface SyncInterventionAction {
    type: 'data/syncIntervention'
    payload: Promise<Intervention[]>
}

// Azione andata a buon fine per scaricare i dati degli interventi
export interface SyncInterventionActionFulfilled {
    type: 'data/syncIntervention_FULFILLED'
    payload: Intervention[]
}

// Azione non andata a buon fine per scaricare i dati degli interventi
export interface SyncInterventionActionRejected {
    type: 'data/syncIntervention_REJECTED'
    payload: Intervention[]
}

// Scarica i dati degli interventi
export function syncIntervention(): SyncInterventionAction {
    return {
        type: 'data/syncIntervention',
        payload: customAxios.get('dashboard/intervention/').then(response => {
            return response.data
        })
    }
}

/**
 * INTERVENTI ATTIVI
 */

// Azione per scaricare i dati degli interventi
interface SyncActiveInterventionAction {
    type: 'data/syncActiveIntervention'
    payload: Promise<Intervention[]>
}

// Azione andata a buon fine per scaricare i dati degli interventi
export interface SyncActiveInterventionActionFulfilled {
    type: 'data/syncActiveIntervention_FULFILLED'
    payload: Intervention[]
}

// Azione non andata a buon fine per scaricare i dati degli interventi
export interface SyncActiveInterventionActionRejected {
    type: 'data/syncActiveIntervention_REJECTED'
    payload: Intervention[]
}

// Scarica i dati degli interventi
export function syncActiveIntervention(): SyncActiveInterventionAction {
    return {
        type: 'data/syncActiveIntervention',
        payload: customAxios.get('dashboard/intervention/active_interventions/').then(response => {
            return response.data
        })
    }
}

/**
 * RADAR TIMESTAMPS
 */

// Azione per scaricare i dati degli interventi
interface SyncRadarMeteoTimestampsAction {
    type: 'data/syncRadarMeteoTimestamps'
    payload: Promise<number>
}

// Azione andata a buon fine per scaricare i dati degli interventi
export interface SyncRadarMeteoTimestampsActionFulfilled {
    type: 'data/syncRadarMeteoTimestamps_FULFILLED'
    payload: number
}

// Azione non andata a buon fine per scaricare i dati degli interventi
export interface SyncRadarMeteoTimestampsActionRejected {
    type: 'data/syncRadarMeteoTimestamps_REJECTED'
    payload: number
}

// Scarica i dati degli interventi
export function syncRadarMeteoTimestamps(): SyncRadarMeteoTimestampsAction {
    return {
        type: 'data/syncRadarMeteoTimestamps',
        payload: axios.get('https://api.rainviewer.com/public/maps.json').then(response => {
            return response.data
        })
    }
}

