import { syncActiveIntervention, syncAlert, syncEarthquake, syncForecast, syncIntervention, syncRadarMeteoTimestamps, syncRiverLevel } from './data'
import { getTeam } from './organization'

export function syncAppStartData() {
    return async (dispatch: any, getState: any) => {
        await dispatch(syncForecast())
        await dispatch(syncRiverLevel())
        await dispatch(syncAlert())
        await dispatch(syncIntervention())
        await dispatch(syncActiveIntervention())
        await dispatch(syncEarthquake())
        await dispatch(getTeam())
        await dispatch(syncRadarMeteoTimestamps())
    }
}
