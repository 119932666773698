// Azione per scaricare la squadra
import axios from '../customComponents/customAxios'
import { User } from '../types/interfaces'

interface GetTeamAction {
    type: 'organization/getTeam'
    payload: Promise<string>
}

// Azione andata a buon fine per scaricare la squadra
export interface GetTeamActionFulfilled {
    type: 'organization/getTeam_FULFILLED'
    payload: User[]
}

// Azione non andata a buon fine per scaricare la squadra
export interface GetTeamActionRejected {
    type: 'organization/getTeam_REJECTED'
    payload: User[]
}

// Fa il login
export function getTeam(): GetTeamAction {
    return {
        type: 'organization/getTeam',
        payload: axios.get('dashboard/user/get_team/').then(response => response.data)
    }
}
