import produce from 'immer'
import { transformAlertData, transformRiverLevel, transformSensorTemperature } from '../functions'
import { ReducerData } from '../types/reducers'
import { ReducerDataActions } from '../types/reducersActions'

const defaultValue: ReducerData = {
    forecast: null,
    riverLevels: null,
    alerts: null,
    earthquakes: null,
    interventions: null,
    activeInterventions: null,
    radarMeteoTimestamps: null
}

export default function dataState(state = defaultValue, action: ReducerDataActions) {
    return produce(state, draft => {
        switch (action.type) {
            case 'data/syncForecast_FULFILLED':
                draft.forecast = {
                    ...action.payload,
                    sensorTemperature: transformSensorTemperature(action.payload.sensorTemperature)
                }
                break
            case 'data/syncRiverLevel_FULFILLED':
                draft.riverLevels = {
                    reno: transformRiverLevel(action.payload.reno),
                    idiceAntonio: transformRiverLevel(action.payload.idiceAntonio),
                    idiceMartino: transformRiverLevel(action.payload.idiceMartino)
                }
                break
            case 'data/syncAlert_FULFILLED':
                draft.alerts = {
                    today: transformAlertData(action.payload.today),
                    tomorrow: transformAlertData(action.payload.tomorrow)
                }
                break
            case 'data/syncEarthquake_FULFILLED':
                draft.earthquakes = action.payload
                break
            case 'data/syncIntervention_FULFILLED':
                draft.interventions = action.payload
                break
            case 'data/syncActiveIntervention_FULFILLED':
                draft.activeInterventions = action.payload
                break
            case 'data/syncRadarMeteoTimestamps_FULFILLED':
                draft.radarMeteoTimestamps = action.payload
                break
            default:
                break
        }
    })
}
