import { persistCombineReducers } from 'redux-persist'
import storage from 'redux-persist/es/storage'
import data from './data'
import graphic from './graphic'
import organization from './organization'
import settings from './settings'
import user from './user'

const config = {
    key: 'root',
    storage,
    blacklist: ['data', 'graphic', 'organization']
}

const rootReducer = persistCombineReducers(config, {
    // @ts-ignore
    data,
    user,
    // @ts-ignore
    graphic,
    settings,
    // @ts-ignore
    organization
})

export default rootReducer
