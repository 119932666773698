import React from 'react'
// import RadarMeteo from '../elements/RadarMeteo'
// import MeteoCard from '../elements/MeteoCard'

interface State {
    // State
}

class WeatherPage extends React.Component<State> {
    render() {
        return (
            <div className="App">
                {/*<MeteoCard forecast={forecast} />
                <div className="card">
                    <div className="day-section">
                        <span className="gradient-text">giovedì</span>
                        <div className={forecast.currently.icon} />
                        <div className="min-max-container">
                            <span className="max">{forecast.daily.data[0].temperatureMax}°</span>
                            <span className="min">{forecast.daily.data[0].temperatureMin}°</span>
                        </div>
                    </div>
                    <div className="day-section">
                        <span className="gradient-text">giovedì</span>
                        <div className={forecast.currently.icon} />
                        <div className="min-max-container">
                            <span className="max">{forecast.daily.data[1].temperatureMax}°</span>
                            <span className="min">{forecast.daily.data[1].temperatureMin}°</span>
                        </div>
                    </div>
                    <div className="day-section">
                        <span className="gradient-text">giovedì</span>
                        <div className={forecast.currently.icon} />
                        <div className="min-max-container">
                            <span className="max">{forecast.daily.data[2].temperatureMax}°</span>
                            <span className="min">{forecast.daily.data[2].temperatureMin}°</span>
                        </div>
                    </div>
                    <div className="day-section">
                        <span className="gradient-text">giovedì</span>
                        <div className={forecast.currently.icon} />
                        <div className="min-max-container">
                            <span className="max">{forecast.daily.data[3].temperatureMax}°</span>
                            <span className="min">{forecast.daily.data[3].temperatureMin}°</span>
                        </div>
                    </div>
                    <div className="day-section">
                        <span className="gradient-text">giovedì</span>
                        <div className={forecast.currently.icon} />
                        <div className="min-max-container">
                            <span className="max">{forecast.daily.data[4].temperatureMax}°</span>
                            <span className="min">{forecast.daily.data[4].temperatureMin}°</span>
                        </div>
                    </div>
                    <div className="day-section">
                        <span className="gradient-text">giovedì</span>
                        <div className={forecast.currently.icon} />
                        <div className="min-max-container">
                            <span className="max">{forecast.daily.data[5].temperatureMax}°</span>
                            <span className="min">{forecast.daily.data[5].temperatureMin}°</span>
                        </div>
                    </div>
                    <div className="day-section">
                        <span className="gradient-text">giovedì</span>
                        <div className={forecast.currently.icon} />
                        <div className="min-max-container">
                            <span className="max">{forecast.daily.data[6].temperatureMax}°</span>
                            <span className="min">{forecast.daily.data[6].temperatureMin}°</span>
                        </div>
                    </div>
                </div>*/}
            </div>
        )
    }
}

export default WeatherPage
