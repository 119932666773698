import React from 'react'
import { User } from '../../types/interfaces'
import { Reducers } from '../../types/reducers'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { syncAppStartData } from '../../actions/multiple_actions'
import '../../styles/TeamPage.sass'

interface State {
    // State
}

const dispatchProps = {
    syncAppStartData
}

function mapStateToProps({ data, settings, organization }: Reducers) {
    return {
        data,
        settings,
        organization
    }
}

interface Props extends DispatchProps, StateProps {}

class TeamPage extends React.Component<Props, State> {
    state = {
        teamModalVisible: false
    }

    openTeamModal = (): void => {
        this.setState({
            teamModalVisible: true
        })
    }

    closeTeamModal = (): void => {
        this.setState({
            teamModalVisible: false
        })
    }

    renderUser(user: User) {
        return (
            <div key={user.id.toString()} className="team-member">
                <div className="member-image">
                    <div className="remove-button">
                        <div className="remove-icon" />
                    </div>
                    <div className="member-photo" />
                </div>
                <span className="member-name">{`${user.firstName} ${user.lastName}`}</span>
            </div>
        )
    }

    render() {
        return (
            <div className={`App ${this.props.settings.drawerCollapsed ? 'resized-content' : ''}`}>
                <div className={`modal-panel ${this.state.teamModalVisible ? 'visible' : ''}`}>
                    <div className="dark-cover" />
                    <div className="card modal">
                        <div className="card-header">
                            <h1 className="card-title">Aggiungi un utente alla tua squadra</h1>
                            <div className="remove-icon" onClick={() => this.closeTeamModal()} />
                        </div>
                        <div className="card-body">
                            <input type="text" name="" id="" />
                        </div>
                        <div className="card-footer">
                            <button className="primary-button">Aggiungi alla squadra</button>
                            <button className="primary-button">Crea un nuovo utente</button>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-header">
                        <h1 className="card-title">Gestisci la tua squadra</h1>
                    </div>
                    <div className="card-body">
                        {this.props.organization.team.length === 0 ? (
                            <span>Al momento la tua squadra non è composta da nessun utente, per cominciare aggiungine uno.</span>
                        ) : (
                            <div className="team-container">
                                {this.props.organization.team.map(user => {
                                    return this.renderUser(user)
                                })}
                                <div className="team-member add-member">
                                    <div className="add-image" onClick={() => this.openTeamModal()}>
                                        <div className="add-icon" />
                                    </div>
                                    <span className="member-name">Aggiungi un nuovo membro</span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

type DispatchProps = typeof dispatchProps
type StateProps = ReturnType<typeof mapStateToProps>

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(dispatchProps, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(TeamPage)
