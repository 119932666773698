import React from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { syncAppStartData } from '../../actions/multiple_actions'
import { connect } from 'react-redux'
import { Reducers } from '../../types/reducers'
import '../../styles/SettingsPage.sass'

const dispatchProps = {
    syncAppStartData
}

function mapStateToProps({ user, settings }: Reducers) {
    return {
        user,
        settings
    }
}

interface Props extends DispatchProps, StateProps {
    //Props
}

interface State {}

class SettingsPage extends React.Component<Props, State> {
    render() {
        const user = this.props.user
        return (
            <div className={`App ${this.props.settings.drawerCollapsed ? 'resized-content' : ''}`}>
                <div className="card settings-card">
                    <div className="card-header">
                        <h1 className="card-title">Riepilogo dati</h1>
                    </div>
                    <div className="card-body">
                        <div className="photo-wrapper">
                            <div className="big-user-sprite"></div>
                            <div className="photo-action">
                                <span>Modifica la foto del profilo</span>
                            </div>
                        </div>
                        <div className="info-wrapper">
                            <div className="info-list">
                                <span>Nome utente: {`${user.firstName} ${user.lastName}`}</span>
                                <span>Associazione: Vigili del fuoco</span>
                                <span>Permessi utente: Volontario</span>
                            </div>
                            <div className="actions-list">
                                <button className="primary-button">Modifica i dati</button>
                                <button className="important-button">Elimina il profilo</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section telegram-section">
                    <h1 className="section-title">Collegamento a Telegram</h1>
                    <div className="card settings-card telegram-card">
                        <div className="telegram-sprite"></div>
                        <div className="actions-list">
                            <div className="qr-container"></div>
                            <a href="#">@mida_bot</a>
                        </div>
                        <div className="actions-list">
                            <button className="important-button">Elimina associazione bot</button>
                        </div>
                    </div>
                </div>
                <div className="card settings-card">Collegati al bot di Telegram per rimanere sempre aggiornato tramite notifica.</div>
                <div className="card settings-card">
                    <div className="card-header">
                        <h1 className="card-title">Notifiche</h1>
                    </div>
                    <div className="card-body">
                        <table className="notify-table">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Telegram</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="name-element">Allerte fiumi</td>
                                    <td className="check-element">
                                        <div className="md-checkbox">
                                            <input id="checkOne" type="checkbox" />
                                            <label htmlFor="checkOne"></label>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="name-element">Allerte meteo</td>
                                    <td className="check-element">
                                        <div className="md-checkbox">
                                            <input id="checkTwo" type="checkbox" />
                                            <label htmlFor="checkTwo"></label>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="name-element">Interventi</td>
                                    <td className="check-element">
                                        <div className="md-checkbox">
                                            <input checked={user.acceptInterventionNotifiers} id="checkThree" type="checkbox" />
                                            <label htmlFor="checkThree"></label>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}

type DispatchProps = typeof dispatchProps
type StateProps = ReturnType<typeof mapStateToProps>

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(dispatchProps, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage)
