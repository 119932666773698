import { THRESHOLDS } from '../../constants/enums'
import customMoment from '../../customComponents/customMoment'
import React from 'react'
import { getLevelClass } from '../../functions'
import { River } from '../../types/api'
import '../../styles/elements/RiverLevel.sass'

interface Props {
    river: River
}

interface State {
    // State
}

export default class RiverLevel extends React.Component<Props, State> {
    transformDate(timestamp: number) {
        return customMoment(timestamp).format('DD/MM/YYYY HH:mm')
    }

    shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
        return this.props.river.data[0].v !== nextProps.river.data[0].v
    }

    render() {
        const river = this.props.river

        const lastUpdate = river.data[0]
        const secondLast = river.data[1]

        const increment = lastUpdate.v > secondLast.v

        const thresholdClass = getLevelClass(lastUpdate.v, river.thresholds)

        if (thresholdClass === THRESHOLDS.ZERO)
            return null

        return (
            <div className="card river">
                <div className="left-section">
                    <div className={`alert-color ${thresholdClass}`} />
                </div>
                <div className="right-section">
                    <span className="gradient-text">{`${river.name}`}</span>
                    <div className="value-section">
                        <span className="level">{`${lastUpdate.v}`}</span>
                        <div className={`triangle ${!increment ? 'triangle-down' : ''}`}></div>
                    </div>
                    <span className="small-info">{`${river.station} ${this.transformDate(lastUpdate.t)}`}</span>
                </div>
            </div>
        )
    }
}
