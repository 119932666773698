import React from 'react'
import '../../styles/elements/Login.sass'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { login } from '../../actions/user'
import { Reducers } from '../../types/reducers'

const dispatchProps = {
    login
}

function mapStateToProps({ graphic }: Reducers) {
    return {
        graphic
    }
}

interface Props extends DispatchProps, StateProps {
    //Props
}

interface State {
    username: string
    password: string
    passwordVisible: boolean
}

class Login extends React.Component<Props, State> {
    constructor(props) {
        super(props)

        this.state = {
            username: '',
            password: '',
            passwordVisible: false
        }
    }

    checkEmptyData() {
        return this.state.username === '' || this.state.password === ''
    }

    render() {
        return (
            <div className="login-panel">
                <div className="name-logo"></div>
                <div className="card login">
                    <div className="login-wrapper">
                        <div className="input-section">
                            <div className="input-container">
                                <div className="group">
                                    <input
                                        name="username"
                                        onChange={e => {
                                            this.setState({
                                                username: e.target.value
                                            })
                                        }}
                                        type="text"
                                        required
                                    />
                                    <span className="highlight" />
                                    <span className="bar" />
                                    <label>Username</label>
                                </div>
                            </div>
                            <div className="input-container">
                                <div className="group">
                                    <input
                                        name="password"
                                        onChange={e => {
                                            this.setState({
                                                password: e.target.value
                                            })
                                        }}
                                        type={this.state.passwordVisible ? 'text' : 'password'}
                                        required
                                    />
                                    <span className="highlight" />
                                    <span className="bar" />
                                    <label>Password</label>
                                </div>
                                <div
                                    className="see-button"
                                    onClick={() => {
                                        this.setState({
                                            passwordVisible: !this.state.passwordVisible
                                        })
                                    }}
                                >
                                    <i className="material-icons">{this.state.passwordVisible ? 'visibility_off' : 'visibility'}</i>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.props.graphic.loginError ? <span className="error">Username o password errati</span> : null}
                    <button
                        onClick={() => {
                            this.props.login({
                                username: this.state.username,
                                password: this.state.password
                            })
                        }}
                        disabled={this.checkEmptyData()}
                    >
                        Login
                    </button>
                </div>
                <div className="credits">
                    Made by <a href="https://massbit.dev/">Massbit</a>
                </div>
            </div>
        )
    }
}

type DispatchProps = typeof dispatchProps
type StateProps = ReturnType<typeof mapStateToProps>

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(dispatchProps, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
