import React from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { syncAppStartData } from '../../actions/multiple_actions'
import '../../styles/InterventionsPage.sass'
import { connect } from 'react-redux'
import { Intervention } from '../../types/api'
import { Reducers } from '../../types/reducers'
import InterventionTable from '../elements/InterventionTable'
import ReactMapGL, { Marker } from 'react-map-gl'
import { MAPBOX_TOKEN } from '../../constants/env_variables'

const dispatchProps = {
    syncAppStartData
}

function mapStateToProps({ data, settings }: Reducers) {
    return {
        data,
        settings
    }
}

interface Props extends DispatchProps, StateProps {}

interface State {
    selectedIntervention: Intervention
    viewport: any
}

class InterventionsPage extends React.Component<Props, State> {
    state = {
        selectedIntervention: null,
        viewport: { longitude: 44.6060402, latitude: 11.6589668, zoom: 12 }
    }

    clickIntervention(intervention: Intervention) {
        if (!this.state.selectedIntervention) {
            this.setState({
                selectedIntervention: intervention
            })
        } else {
            if (intervention.uuid === this.state.selectedIntervention.uuid) {
                this.setState({
                    selectedIntervention: null
                })
            } else {
                this.setState({
                    selectedIntervention: intervention
                })
            }
        }
    }

    render() {
        const interventions = this.props.data.interventions

        if (!interventions) return null

        return (
            <div className={`App ${this.props.settings.drawerCollapsed ? 'resized-content' : ''}`}>
                <div className="body-section">
                    <div className="section">
                        <div className="map-container">
                            <ReactMapGL
                                mapboxApiAccessToken={MAPBOX_TOKEN}
                                width="100%"
                                height={800}
                                latitude={44.6060402}
                                longitude={11.6589668}
                                zoom={this.state.viewport.zoom}
                                mapStyle="mapbox://styles/mapbox/dark-v9"
                                onViewportChange={viewport => this.setState({ viewport })}
                            >
                                <Marker latitude={44.6060402} longitude={11.6589668} offsetLeft={-40} offsetTop={-50}>
                                    <div className="firestation-sprite" />
                                </Marker>
                                {this.props.data.activeInterventions.map(activeIntervention => {
                                    return (
                                        <Marker
                                            latitude={activeIntervention.position.latitude}
                                            longitude={activeIntervention.position.longitude}
                                            offsetLeft={-40}
                                            offsetTop={-50}
                                        >
                                            <img
                                                src="https://s3.eu-central-1.amazonaws.com/citycommerce/media/public/pin.svg"
                                                height={50}
                                                width={50}
                                                alt="Intervention"
                                            />
                                        </Marker>
                                    )
                                })}
                                {this.state.selectedIntervention ? (
                                    <Marker
                                        latitude={this.state.selectedIntervention.position.latitude}
                                        longitude={this.state.selectedIntervention.position.longitude}
                                        offsetLeft={-40}
                                        offsetTop={-50}
                                    >
                                        <div className="place-sprite" />
                                    </Marker>
                                ) : null}
                            </ReactMapGL>
                        </div>
                    </div>

                    <div className="section history-section">
                        <h1 className="section-title">Storico interventi</h1>

                        <InterventionTable onClickIntervention={intervention => this.clickIntervention(intervention)} data={interventions} />
                    </div>
                </div>
            </div>
        )
    }
}

type DispatchProps = typeof dispatchProps
type StateProps = ReturnType<typeof mapStateToProps>

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(dispatchProps, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(InterventionsPage)
